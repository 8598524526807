:root {
  --background: #d1d1d1;
  --text-primary: #333333;
  --text-secondary: #004a7c;
  --text-accent: #a06c0c;
  --error: #ff6347;
  --success: #32cd32;
  --neutral: #808080;
  --logo-color: #24292f;
  --ff-primary: "Roboto", sans-serif;
  --modal-background: rgb(222, 227, 230);
}

[data-theme="dark"] {
  --background: #1d1d20;
  --text-primary: #d3d3d3;
  --text-secondary: #4ecdc4;
  --text-accent: #ffd700;
  --error: #ff6347;
  --success: #32cd32;
  --neutral: #a9a9a9;
  --logo-color: #fff;
  --modal-background: #f6eee3;
}

[data-theme="About_me"] {
  --error: purple;
  --success: red;
}
[data-theme="Projects"] {
  --error: green;
  --success: blue;
}
[data-theme="Contacts"] {
  --error: yellow;
  --success: green;
}

.app {
  overflow-y: auto;
  min-height: 100vh;
  display: grid;
}

TimelineContent {
  color: var(--text-primary);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 0.5s;
}

p,
span,
h1,
h2,
h3 {
  font-family: var(--ff-primary);
}

p {
  margin-top: 12px;
  font-size: 16px;
}

.right_section {
  padding: 2rem 2rem;
}

.content.show {
  opacity: 1;
}

.content.hide {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.timeline {
  width: 500px;
}

.about_me_section {
  display: flex;
  position: relative;
  justify-items: center;
  justify-items: center;
}

.about_me_description {
  display: flex;
  flex-direction: column;
  padding: 0 0px;
  justify-content: center;
}

.menu_item {
  font-size: 1em;
  font-family: "Times New Roman", Times, serif;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  -webkit-tap-highlight-color: transparent;
}

.underlined::after {
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 3px;
  background: var(--success);
  background: -webkit-linear-gradient(to right, var(--error), var(--success));
  background: linear-gradient(to right, var(--error), var(--success));
  animation: fade 0.5s ease forwards;
}

span {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

p,
span {
  color: var(--text-primary);
}

h1,
h2,
h3 {
  color: var(--text-secondary);
  line-height: 1;
  font-weight: 900;
  text-shadow: black;
}

.Language_button {
  position: fixed;
  background-color: var(--text-secondary);
  aspect-ratio: 1;
  color: white;
  border: none;
  padding: 5px;
  font-size: 18px;
  height: 3rem;
  width: 3rem;
  top: 0px;
  left: 0px;
  box-shadow: 0 2px 4px darkslategray;
  cursor: pointer;
  border-radius: 70%;
  transition: all 0.2s ease;
}

li {
  margin-top: 20px;
  size: 1rem;
}

.contact_reference {
  font-size: 45px;
  color: var(--logo-color);
}

.contact_me_description {
  padding: 0 30px;
  width: 400px;
}

.contact_container {
  display: flex;
  align-items: center;
}

.get_in_touch {
  padding-top: 2rem;
}

.contact_list > * {
  margin-left: 1rem;
}

.contact_list > *,
.get_in_touch {
  padding-left: 2rem;
}

.content,
.main-container,
.app,
header {
  background-color: var(--background);
  z-index: 0;
}

.DarkModeToggle,
.Language_button {
  z-index: 1;
  margin: 1em;
  position: fixed;
}

.DarkModeToggle {
  top: 0px;
  right: 0px;
}

.app {
  font-size: 1.3125rem;
  line-height: 1.6;
}

.main-container {
  align-items: center;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.content {
  display: flex;
  flex-basis: 100%;
  min-height: calc(100% - 222px);
  width: 100vw;
  max-width: 1280px;
  padding: 2.5rem;
  align-self: center;
  justify-content: flex-end;
  overflow: hidden;
}

.left,
.right {
  align-self: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 3%;
}

.left {
  flex-basis: 30%;
  padding-right: 2rem;
}

.right {
  flex-basis: 70%;
  background-color: var(--background);
}

.right_content[fade="1"] {
  animation: dissolveInLeft 0.4s ease-in forwards;
}

.profile_picture {
  border-radius: 50%;
  max-width: 350px;
  aspect-ratio: 1 / 1;
  border: 3px solid var(--text-primary);
}

@keyframes dissolveInLeft {
  0% {
    opacity: 0;
    transform: translate3d(200px, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.menu {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--background);
  padding-left: 2rem;
  padding-bottom: 20px;
}

.fade_in_anim {
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-name: fade;
}

.menu > * {
  opacity: 0;
  flex-basis: 100%;
  margin: 0.5rem 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-name: fade;
}

.menu > *:nth-child(1) {
  animation-delay: 0.2s; /* No delay for the first child */
}

.menu > *:nth-child(2) {
  animation-delay: 0.6s; /* Slight delay for the second child */
}

.menu > *:nth-child(3) {
  animation-delay: 1s; /* Delay for the third child */
}

a {
  color: var(--text-secondary);
  text-align: right;
}

p {
  color: var(--text-primary);
}

.modal_box {
  z-index: 0;
  border-radius: 1%;
}

.modal_box::before,
.modal_box::after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 10rem;
  height: 10rem;
  transform: translate(-328px, -8px);
  background: linear-gradient(
    136deg,
    var(--error) 0%,
    var(--success) 50%,
    var(--error) 100%
  );
  border-radius: 10%;
}

.modal_box::after {
  background: white;
  transform: translate(-320px);
}

.card::before {
  z-index: -1;
  content: "";
  position: absolute;
  width: 5rem;
  height: 5rem;
  transform: translate(-5px, -5px);
  background: linear-gradient(
    136deg,
    var(--error) 0%,
    var(--success) 50%,
    var(--error) 100%
  );
  border-radius: 10%;
}

.card {
  aspect-ratio: 1/1;
  z-index: 0;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.card:hover {
  --success: rgb(170, 0, 255);
  --neutral: rgb(0, 106, 255);
  transform: translatey(0px);
  animation: float 1s ease-in-out infinite;
  background-color: var(--error);
}
.left::before {
  z-index: -1;
  content: "";
  display: flex;
  position: relative;
  width: 5rem;
  height: 5rem;
  top: 311px;
  left: -10px;
  background: linear-gradient(
    136deg,
    var(--error) 0%,
    var(--success) 50%,
    var(--error) 100%
  );
  border-radius: 10%;
}

.right_accent::before {
  z-index: -2;
  content: "";
  display: block;
  position: absolute;
  width: 10rem;
  height: 10rem;
  transform: translate(-15px, -15px);
  background: linear-gradient(
    136deg,
    var(--error) 0%,
    var(--success) 50%,
    var(--error) 100%
  );
  border-radius: 10%;
  opacity: 100%;
}

.right_accent::after {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  width: 10rem;
  height: 10rem;
  background: var(--background);
  border-radius: 10%;
  opacity: 100%;
}

a {
  padding-left: 0.5em;
}

.top-right-box,
.bottom-right-box {
  z-index: -1;
  position: absolute;
  border-radius: 10%;
  width: 11rem;
  height: 11rem;
  background-color: var(--background);
}

.bottom-right-box {
  align-self: flex-end;
}

.gradient {
  width: 9rem;
  height: 9rem;
  transform: translate(1rem, 1rem);
  background: linear-gradient(
    136deg,
    var(--error) 0%,
    var(--success) 50%,
    var(--error) 100%
  );
  border-radius: 10%;
}

.gradient-top {
  transform: translate(1rem, -1rem);
}

.name_svg {
  width: 250px;
}

.name_svg > * {
  stroke: var(--text-primary);
  fill: var(--background);
}

.name_svg path:nth-child(1) {
  stroke-dasharray: 582;
  stroke-dashoffset: 582;
  animation: line-animation 2s ease forwards 3s, fill 0.5s ease forwards 3.3s;
}

.name_svg path:nth-child(2) {
  stroke-dasharray: 736;
  stroke-dashoffset: 736;
  animation: line-animation 2s ease forwards 2.7s, fill 0.5s ease forwards 3.3s;
}
.name_svg path:nth-child(3) {
  stroke-dasharray: 343;
  stroke-dashoffset: 343;
  animation: line-animation 2s ease forwards 2.4s, fill 0.5s ease forwards 3.3s;
}
.name_svg path:nth-child(4) {
  stroke-dasharray: 685;
  stroke-dashoffset: 685;
  animation: line-animation 2s ease forwards 2.1s, fill 0.5s ease forwards 3.3s;
}

.name_svg path:nth-child(5) {
  stroke-dasharray: 488;
  stroke-dashoffset: 488;
  animation: line-animation 2s ease forwards 1.8s, fill 0.5s ease forwards 3.3s;
}
.name_svg path:nth-child(6) {
  stroke-dasharray: 702;
  stroke-dashoffset: 702;
  animation: line-animation 2s ease forwards 1.5s, fill 0.5s ease forwards 3.3s;
}

.name_svg path:nth-child(7) {
  stroke-dasharray: 685;
  stroke-dashoffset: 685;
  animation: line-animation 2s ease forwards 1.2s, fill 0.5s ease forwards 3.3s;
}
.name_svg path:nth-child(8) {
  stroke-dasharray: 345;
  stroke-dashoffset: 345;
  animation: line-animation 2s ease forwards 0.9s, fill 0.5s ease forwards 3.3s;
}
.name_svg path:nth-child(9) {
  stroke-dasharray: 578;
  stroke-dashoffset: 578;
  animation: line-animation 2s ease forwards 0.6s, fill 0.5s ease forwards 3.3s;
}

.name_svg path:nth-child(10) {
  stroke-dasharray: 472;
  stroke-dashoffset: 472;
  animation: line-animation 2s ease forwards 0.3s, fill 0.5s ease forwards 3.3s;
}

.floating {
  transform: translatey(0px);
  animation: float 4s ease-in-out infinite;
}

.mail_path {
  height: 25px;
  width: 25px;
}

.project_list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1em;
  z-index: 10;
}

li {
  display: flex;
  align-items: center;
}

svg {
  display: block;
}

.card_title,
.card_desc {
  color: black;
}

.modal_box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50rem;
  min-height: 25rem;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.right_modal {
  margin: 0 20px 80px 40px;
}

.left_modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 20px;
  min-width: 250px;
}
.left_modal,
.right_modal {
  padding-top: 20px;
}

.modal_title {
  color: black;
  padding-bottom: 20px;
}

.modal_icon {
  padding: 0;
  text-align: center;
  font-size: 75px;
  color: black;
  align-self: center;
  justify-self: center;
}

.tech_stack_modal > * {
  margin: 0;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 20px;
  color: black;
}

.github_link {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes line-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fill {
  from {
    fill: var(--background);
  }
  to {
    fill: var(--text-primary);
  }
}

@media (max-width: 1100px) {
  .project_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1em;
    z-index: 10;
  }
}

@media (max-width: 950px) {
  .content {
    padding: 0 5rem;
    flex-direction: column;
    margin-bottom: 5rem;
  }
  .modal_box {
    width: 40rem;
    flex-direction: column;
  }
  .about_me_section {
    flex-direction: column;
  }

  .left,
  .right {
    position: relative;
    flex: 1;
    padding: 0;
    margin: 0 0;
    margin-top: 1em;
  }

  .left {
    padding: 2.5rem 0;
  }

  .right {
    margin-bottom: auto;
  }

  .top-right-box {
    display: none;
  }

  .contact_container {
    flex-direction: column;
  }

  .contact_reference {
    padding: 0;
  }

  ul > li {
    display: inline-block;
    margin-left: 40px;
    /* You can also add some margins here to make it look prettier */
  }

  .tech_stack_modal > * {
    display: flex;
  }
  .right_modal,
  .left_modal {
    margin: 0px 20px;
  }
}

@media (max-width: 680px) {
  .modal_box {
    width: 25rem;
  }
}

@media (max-width: 450px) {
  .content {
    padding: 0 1rem;
    flex-direction: column;
    justify-content: end;
    align-items: flex-end;
  }

  .left,
  .right {
    padding: 0;
    margin: 0 0;
    margin-top: 1em;
  }

  .left {
    height: auto;
    padding: 2.5rem 0;
  }

  .modal_box {
    width: 25rem;
  }
}
